import axios from './axios'
import { getDateRequest } from '../utils/dateRequest.js'

export async function getData(){
  return await axios.get(`resultados?acao=ultimoFinalSemana${getDateRequest()}`)
}

// funcao duplicada em resultados
export async function listaTrimestres(){
  return await axios.get(`resultados?acao=listaFinalSemana${getDateRequest()}`)
}

// funcao duplicada em resultados
export async function getTrimestre(id){
  return await axios.get(`resultados?acao=getFinalSemana&id_importacao=${id}${getDateRequest()}`)
}