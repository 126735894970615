import React  from 'react'

import {useHistory, useParams} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {aceitaTermos} from '../../core/ducks/user.duck'
import Regulamento from '../../pages/Regulamento'

export default (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {interno} = useParams()
  const {user, loading} = useSelector(state=>state.userReducer)
  let pageinterno = interno === "interno" 
  
  const actionAccept = () => {
    dispatch(aceitaTermos({id:user.id, acao: "aceitaTermos"}))
    history.push("/home")
  }

  return <Regulamento actionAccept={()=>actionAccept()} interno={pageinterno} />
}