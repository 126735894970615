import React from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom"
import {useSelector} from 'react-redux'
import Login from '../../containers/login'
import Regulamento from '../../containers/regulamento'
import ObjetivosAno from '../../containers/objetivosAno'
import ObjetivosPeriodo from '../../containers/objetivosPeriodo'
import Ganhadores from '../../containers/ganhadores'
import Resultados from '../../containers/resultados'
import Mural from '../../containers/mural'
import FaleConosco from '../../containers/faleConosco'
import Profile from '../../containers/profile'
import Produtos from '../produtos'

export default () => {
  const {user} = useSelector(state=>state.userReducer)
 
  return (
      <Switch>
        {
          !user.id && (
            <>
              <Redirect path="/" to="/login" exact />
              <Route path="/login"><Login /></Route>
            </>
          )
        }
        <Redirect path="/" to="/login" exact />
        <Route path="/login"><Login /></Route>
        <Route path="/regulamento/:interno"><Regulamento /></Route>
        <Route path="/regulamento"><Regulamento /></Route>
        <Route path="/home"><ObjetivosAno /></Route>
        <Route path="/objetivosperiodo" exact><ObjetivosPeriodo /></Route>
        <Route path="/ganhadores"><Ganhadores /></Route>
        <Route path="/resultados"><Resultados /></Route>
        <Route path="/mural"><Mural /></Route>
        <Route path="/faleconosco"><FaleConosco /></Route>
        <Route path="/profile"><Profile /></Route>
        <Route path="/Medicamentos"><Produtos /></Route>
        <Redirect to="/login" />

      </Switch>
  )
}