import React from "react";
import Skeleton from "../Skeleton";
import ChangeTypeProdutos from '../../components/ChangeTypeProdutos'
import Apuracao from '../../components/apuracao'
import { BeatLoader } from 'react-spinners'
import LinhaTabelaProdutos from "../../components/linhaTabelaProdutos";

const headWidth = {
  "QUANTIDADE DE ITENS": { width: 120 },
  "CÓDIGO": { width: 80 },
  "MARCA": { width: 140 },
  "PRODUTO": { flex:1},
  "BASE": { width: 80 },
}



export default props => {
  const data = props.data
  const config = props.config
  

  return (

    <Skeleton menuHidden>
      <Apuracao config={config} />
      <div style={{ display: "flex" }}>
        <div className="w100"></div>
        
        <div className="flex2 flexTopo">
          <div className="tituloCaps" style={{ backgroundColor: props.config.color, float: "left" }}>
            {props.config.titulo}
          </div>
          <span className="tituloSecundario" style={{ color: data.color }}>{data.titulo}</span>
        </div>

        <div className="flex1">
          <div className="baseFilter">
            <input type="text" placeholder="Pesquise aqui" onChange={(e) => props.filterText(e.target.value)} />
          </div>
        </div>

      </div>

      {/* Responsavel por mandar o  setar os dados das linhas da tabela*/}
      <div style={{ display: "flex", marginTop: 20 }}>
        <div className="w100" style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
          <ChangeTypeProdutos 
            screen={props.screen} 
            type={props.type} 
            action={(e) => props.changeType(e)} 
          />
        </div>
        <div className="flex2">
          <div className="baseTable">
             {/* responsavel por pegar o header das listas na tabela e criar os titulos da tabela */}
              <div className="headerTable">
              {data.lista.header.map((v, i) => <strong style={{ ...(headWidth[v.toUpperCase()] ? headWidth[v.toUpperCase()] : headWidth.DEFAULT) }} key={i}>{v}</strong>)}
            </div>

            {/* loading */}
               <div className="baseTableConteudo">
                {props.loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 300 }}>
                  <BeatLoader
                    sizeUnit={"px"}
                    size={15}
                    color={'rgba(47, 119, 146)'}
                    loading={props.loading}
                  />
                </div>
              )}

              {/* manda os valores das prolps para o tracker das linhas da tabela */}
              {!props.loading && data.lista.data.filter(props.filtroAction).map((v, i) => 
                <LinhaTabelaProdutos type={props.type} base={v.type} quantity={v.quantity} id={v.id} brand={v.brand}  name={v.name}/>
              )
              }
              

            {/* usado quando não tem dados da tabela */}
              {!props.loading && data.lista.data.length === 0 && <div className="tableMensagem">NÃO EXISTEM PRODUTOS NESTE GRUPO</div>}
            
            
            </div> 
          </div>
        </div>
      </div>
    </Skeleton>
  );
};


