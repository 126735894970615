import React from "react";


export default props => {
  return (
    <div
      className="bodyBase"
      style={{...props.style}}
    >
      {props.children}

    </div>
  );
};

  