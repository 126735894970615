import React from "react";
import Skeleton from '../Skeleton'

export default props => {

  let avatar = require("../../assets/images/avatar.png")

  if(props.user.imagem !== null){
    avatar = process.env.REACT_APP_BASEIMG + props.user.imagem
  }

  const imgProfile = props.picture != null ? props.picture.imagePreviewUrl : avatar 
  //http://cristalia.agenciamark2.com/admin/uploads/colaboradores/

  return (
    <Skeleton>
      <div style={{display:'flex', marginTop:40}}>
      <div className={"baseAvatar"}>
        <img
          onClick={()=>{}}
          src={imgProfile}
          className={"avatar"}
        />

        <label for="upload">
          <div className={"botao"} onClick={()=>{}}> Escolher foto </div> 
          <input style={{display:'none'}} id="upload" type="file" onChange={(e)=>props._handleImageChange(e)} />
        </label>  

        {props.picture && <span className={"botao"} style={{backgroundColor: 'green'}} onClick={()=>props.saveImg()}>Salvar</span>}
        
      </div>

        <div className={"cardProfile"}>

        <div className={"dados"}>
          <strong>Nome</strong>
          <span>{props.user.nome}</span>
        </div>
        <div className={"dados"}>
          <strong>Setor</strong>
          <span>{props.user.setor}</span>
        </div>
        <div className={"dados"}>
          <strong>E-mail</strong>
          <span>{props.user.email}</span>
        </div>
        <div className={"dados"}>
          <strong>Celular Corporativo</strong>
          <span>{props.user.celular_corporativo}</span>
        </div>


        </div>
      </div>
    </Skeleton>
  );
}
