import * as MuralAPI from "../services/mural.service"

export const type = {
  MURAL_SET_LIST: 'MURAL_SET_LIST'
}


const initial = {
  list: [],
  loading: true, 
}

const reducer = (state=initial, action)=>{
  switch(action.type){
    case type.MURAL_SET_LOADING:
      return {
        ...state, 
        loading: action.payload,
      }
    case type.MURAL_SET_LIST:
      return {
        ...state, 
        list: action.payload, 
        loading: false
      }
    default:
      return state
  }
}

export default reducer 

export const setLoading = () => ({type: "MURAL_SET_LOADING", payload: true})

export const getList = () => async dispatch => {
  dispatch({type: "MURAL_SET_LOADING", payload: true})
  const mural = await MuralAPI.getlist();
  dispatch({type: type.MURAL_SET_LIST, payload: mural.data})
}

export const sendComment = (payload) => async dispatch => {
  const add = await MuralAPI.create(payload);
  const mural = await MuralAPI.getlist();
  dispatch({type: type.MURAL_SET_LIST, payload: mural.data})
}

export const sendContato = (payload) => async dispatch => {
  const add = await MuralAPI.contato(payload);
}
