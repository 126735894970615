import axios from './axios'



export async function getProdutos(type) {
	if (type) {
	return await axios.get(`https://3k7yrw2yq8.execute-api.us-east-1.amazonaws.com/prod/products?type=${type}`)
	} else {
	 return await axios.get(`https://3k7yrw2yq8.execute-api.us-east-1.amazonaws.com/prod/products`)
	}
}


