import React from "react";
import Topo from "../../components/topo";
import Body from "../../components/body";
import Menu from '../../components/menu'
import textoRegulamento from '../../core/utils/regulamento'

function Regulamento(props) {
  return (
    <div className={!props.interno?"fundoLogin":""}>
    <Body>
      <Topo notShowStat={!props.interno} />
      <div
          style={{
          width: 1200,
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center"
        }}
      >
        <div className="tituloCaps" style={{marginLeft:'auto', marginRight:'auto'}}>REGULAMENTO</div>
        <div className="containerRegulamento">
          <div className="textoRegulamento">{textoRegulamento()}</div>
        </div>

        {!props.interno && (

        <div
          style={{
            textAlign: "left",
            color: "#EBF5FC",
            marginTop: 10,
            display: "flex",
            fontSize: 16,
            alignItems: "center",
            marginTop:30,
          }}
        >
          <div className="customCheck" onClick={()=>props.actionAccept()}>
            <input type="checkbox" />
            <span className="checkmark"></span>
            Declaro que li e aceito os termos
          </div>
        </div>
      
        )}
      </div>
      {props.interno && <Menu />}
    </Body>
    </div>
  );
}

export default Regulamento;
