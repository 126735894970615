import React from 'react'

import {useSelector, useDispatch} from 'react-redux'
import {sendContato} from '../../core/ducks/mural.duck'
import FaleConosco from '../../pages/FaleConosco'

export default (props) => {
  const {user} = useSelector(state=>state.userReducer)
  const dispatch = useDispatch();

  function enviaMensagem(mensagem){
    dispatch(sendContato({id_colaboradores:user.id, setor:user.setor, grupo:user.GDIV, mensagem:mensagem,acao: "escreverContato"}));
  }

  return <FaleConosco action={(mensagem) => enviaMensagem(mensagem)} user={user} />
}