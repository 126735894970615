import axios from './axios'
import { getDateRequest } from '../utils/dateRequest.js'

export async function getResultados(id) {
      return await axios.get(`resultados?acao=listaCategoria&categoria=${id}${getDateRequest()}`, {})

}

export async function getResultadosLaterals(id) {
  if (id == 11) {
     return await axios.get(`laterals?titulo=MEGA CAMPEÕES`, {})
  } else if (id == 2) {
    return await axios.get(`laterals?titulo=4 SALÁRIOS`, {})
  } else if (id == 5) {
    return await axios.get(`laterals?titulo=CAMPEÕES DE VENDAS`, {})
  } else if (id == 8) {
    return await axios.get(`laterals?titulo=CASA EQUIPADA`, {})
  } 
}

export async function listaTrimestres(){
  return await axios.get(`resultados?acao=listaFinalSemana${getDateRequest()}`)
}

export async function getTrimestre(id){
  return await axios.get(`resultados?acao=getFinalSemana&id_importacao=${id}${getDateRequest()}`)
}