import React, { useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {getList, sendComment} from '../../core/ducks/mural.duck'
import Mural from '../../pages/Mural'


export default (props) => {
  const {loading, list} = useSelector(state=>state.muralReducer)
  const {user} = useSelector(state=>state.userReducer)
  const dispatch = useDispatch();

  function enviaMural(v){
    dispatch(sendComment({id_colaboradores:user.id, texto:v,  acao: "escreveMural"}));
  }

  useEffect(()=>{
    dispatch(getList());
  }, [dispatch])

  return <Mural action={(v) => enviaMural(v)} user={user} list={list} loading={loading} />
}