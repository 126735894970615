import React, {useState} from 'react'
import { useHistory } from 'react-router-dom'

const PAGES = [
  {
    icon: require('../assets/images/ichome.png'),
    title: 'Home',
    action: "/home",
  },
  {
    icon: require('../assets/images/icregulamento.png'),
    title: 'Regulamento',
    action: "/regulamento/interno",
  },
  {
    icon: require('../assets/images/medicamentos.png'),
    title: 'Produtos Vigentes',
    action: "/medicamentos",
  },
  {
    icon: require('../assets/images/icobjetivos.png'),
    title: 'Objetivos Periodo',
    action: "/objetivosperiodo",
  },
  {
    icon: require('../assets/images/icganhadores.png'),
    title: 'Elegíveis',
    action: "/ganhadores",
  },
  {
    icon: require('../assets/images/icresultados.png'),
    title: 'Resultados',
    action: "/resultados",
  },
  {
    icon: require('../assets/images/icmural.png'),
    title: 'Mural',
    action: "/mural",
  },
  {
    icon: require('../assets/images/icfaleconosco.png'),
    title: 'Fale Conosco',
    action: "/faleconosco",
  },
]
export default (props) => {
  const history = useHistory()
  const showAnimation = props.hidden
  const [hidden, setHidden] = useState(props.hidden)

  if(hidden) {
    return (
      <div className={"baseMenuHidden"}>
        <h3 onClick={()=>setHidden(false)} className={"miniMenu"}>MENU</h3>
      </div>
    )
  }

  return (
    <div className={`baseMenu ${showAnimation&&"baseMenuAnima"}`}>
      {
        PAGES.map((val,i)=>
          <div 
            className={history.location.pathname === val.action&&'ativo'} 
            onClick={()=>history.push(val.action)}>
            <img src={val.icon} style={{width:50, height:50}} alt={'icon'} />
            {val.title}
          </div>)
      }
    </div>
  )
}