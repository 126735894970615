import React, {useState} from 'react'
import Skeleton from "../Skeleton";
import avatar from "../../assets/images/avatar.png";
import ModalConfirmacao from "../../components/modalConfirmacao"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'

export default props => {
  const [mensagem, setValueMensagem] = useState("");
  const [enviado, setValueEnviado] = useState(false);
  const [preencher, setValuePreencher] = useState(false);
  
  const handleChangeMensagem = event => {
    setValueMensagem(event.target.value);
  };

  function sendMessage(){
    if(mensagem != ''){
      props.action(mensagem)
      setValueMensagem('')
      setValueEnviado(true)
    }else{
      setValuePreencher(true)
    }
  }

  return (
    <Skeleton>
      <div className="botaoRegulamento">FALE CONOSCO</div>

      <div style={{ display: "flex", marginTop: 20 }}>
        <div
          className="w10"
          style={{
            backgroundColor: "#FFF",
            color: "#2F7792",
            flex: 1,
            padding: 20,
            borderRadius: 12
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                flex: 1,
                alignItems: "center",
                display: "flex",
                flexDirection: "row"
              }}
            >
              <div>
                <img
                  src={avatar}
                  style={{ width: 50, height: 50, borderRadius: 50 }}
                />
              </div>
              <div style={{ marginLeft: 10 }}>
                {props.user.nome}
              </div>
            </div>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", marginTop: 25 }}
          >
            <div>
              <b>Mensagem</b>
            </div>
            <div style={{ position: "relative" }}>
              <textarea
                rows="10"
                placeholder={"Escreve aqui"}
                style={{
                  width: "100%",
                  fontSize: 14,
                  border: "initial",
                  outline: "initial"
                }}
                onChange={handleChangeMensagem}
                value={mensagem}
              ></textarea>
              <div className="botaoEditFale" onClick={() => sendMessage()}>
                <FontAwesomeIcon icon={faPaperPlane} />
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {enviado&&(
        <ModalConfirmacao title="Contato enviado com sucesso!" botao="Fechar" closeModal={()=>setValueEnviado(false)} />
      )}
      
      {preencher&&(
        <ModalConfirmacao title="Favor preencher todos os campos." botao="OK" closeModal={()=>setValuePreencher(false)} />
      )}
    </Skeleton>
  );
};
