import React from "react";

export default props => {

  let avatar = require('../assets/images/avatar.png')
  if (Boolean(props.imagem)) {
    avatar = process.env.REACT_APP_BASEIMG + props.imagem
  }

  const styles = {
    containerAvatar: {
      display: "flex",
      alignItems: "center",
    },
    containerAvatarMega: {
      display: "flex",
      alignItems: "center",
      width:140,
    },
    avatarImage: {
      borderRadius: 40,
      width: 40,
      height: 40,
    },
    divisor: {
      height: 50,
      width: 2,
      marginLeft: 5,
      marginRight: 10,
      backgroundColor: props.elegivel ? "green" : "tomato",
    },
    group: {
      width: 120,
      fontSize: 12,
    },
    sector: {
      width: 80,
      fontSize: 12,
    },
    name: {
      flex: 1,
      fontSize: 12
    },
    gep: {
      width: 60,
      fontSize: 12,
    },
    gr: {
      width: 80,
      fontSize: 12,
      textAlign:'center'
    },
    gdvi: {
      width: 80,
      fontSize: 12,
      textAlign:'center'
    },
     posicao: {
      width: 80,
       fontSize: 12,
      textAlign:'center'
    },
      periodo: {
      width: 80,
        fontSize: 12,
       textAlign:'center'
    },
    quantidade: {
      width: 80,
      fontSize: 12,
       textAlign:'center'
    },
    eligible: {
      backgroundColor: props.elegivel ? "green" : "tomato",
      height: 50,
      width: 50,
      borderRadius: 50,
      color: "#fff",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: 12,
      float: 'right'
    }
  }

  return (
    <div className="linhaTabela">
      <div style={{...(props.type == 10 ? styles.containerAvatarMega :styles.containerAvatar)}}>
        <img src={avatar} style={styles.avatarImage} alt={'Imagem do avatar'} />
        <div style={styles.divisor} />
        <div style={styles.group}>{props.grupo}</div>
      </div>

      <div style={styles.sector}>{props.setor}</div>

      <div style={styles.name}>{props.nome}</div>


      {
         props.type == 10 &&
        (
          <div style={styles.gdvi}>{props.gdvi}</div>
        )
      }
        {
         props.type == 10 &&
        (
          <div style={styles.gr}>{props.gr}</div>
        )
      }
       {
         props.type == 10 &&
        (
         <div style={styles.quantidade}>{props.quantidade}</div>
        )
      }
       {
         props.type == 10 &&
        (
        <div style={styles.posicao}>{props.posicao}</div>
        )
      }

      {props.type != 10 && (
        <>
    {  props.gep && <div style={styles.gep}>{props.gep}</div>}
      
    {  props.posicao && <div style={styles.posicao}>{props.posicao}</div>}
      
    {props.periodo && <div style={styles.periodo}>{props.periodo}</div>}
    
    { props.type != 6 && props.gr && <div style={styles.gr}>{props.gr}</div>}
      
        </>
      ) }
        <div style={styles.eligible}> {props.elegivel ? "SIM" : "NÃO"} </div>
    </div>
  );
};

