import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import "./style.css";

export default props => {
  const [comentario, setValue] = useState("");

  const handleChange = event => {
    setValue(event.target.value);
  };

  let avatar = require('../assets/images/avatar.png')
  if(Boolean(props.user.imagem)){
    avatar = process.env.REACT_APP_BASEIMG + props.user.imagem
  }

  return (
    <div className={"baseMuralNew"}>
      <div
        style={{ flexDirection: "row", display: "flex", alignItems: "center" }}
      >
        <div>
          <img
            src={avatar}
            style={{ width: 50, height: 50, borderRadius: 50 }}
          />
        </div>
        <div style={{ marginLeft: 10, color: "#2F7792" }}>
          <b>{props.nome}</b>
          <br />
          <small>{props.data}</small>
        </div>
      </div>
      <div>
        <textarea
          rows="5"
          onChange={handleChange}
          style={{
            width: "100%",
            padding: 0,
            paddingTop: 15,
            fontSize: 14,
            border: "initial",
            outline: "initial"
          }}
          placeholder={"Digite o seu comentário..."}
        ></textarea>
      </div>
      <div className="botaoEditMural" onClick={() => props.action(comentario)}>
        <FontAwesomeIcon icon={faPaperPlane} />
      </div>
    </div>
  );
};
