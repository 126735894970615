import React from "react";
import "./style.css";

export default props => {
  let avatar = require('../assets/images/avatar.png')
  if(Boolean(props.dados.imagem)){
    avatar = process.env.REACT_APP_BASEIMG + props.dados.imagem
  }
  
  return (
    <div style={{backgroundColor: '#fff', padding: 10, borderRadius: 12, flexBasis: 'calc(33% - 40px)', marginTop: 15, marginBottom: 15, height: 150, position: 'relative', marginRight: 10, marginLeft: 10}}>
        <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center'}}>
            <div>
                <img src={avatar} style={{width: 50, height: 50, borderRadius: 50}} />
            </div>
            <div style={{marginLeft: 10, color: '#2F7792'}}>
                <b>{props.dados.nome}</b><br/>
                <small>{props.dados.data}</small>
            </div>
        </div>
        <div>
            <p style={{fontSize: 14, color: '#2F7792'}}>{props.dados.texto}</p>
        </div>
    </div>
  );
};
