const RESULTADOS = {
  4: {
    titulo: 'Campeões de Vendas',
    color: '#EDC620', 
    lista: {
      header: ["GRUPO", "SETOR", "NOME", "VENDA 4G", "ELEGÍVEL"],
      data: [],
    }, 
    sidebar: {
      header: ["GRUPO", "ELEGÍVEIS ", "QTDE DE PRÊMIOS"],
      data: [],
    }
  }, 
  6: {
    titulo: 'Casa Equipada',
    color: 'rgb(25, 218, 0)', 
    lista: {
      header: ["GRUPO", "SETOR", "NOME", "VENDA TOTAL", "ELEGÍVEL"],
      data: [],
    }, 
    sidebar: {
      header: ["GRUPO", "QTD DE PREMIOS", "PARTICIPANTES"],
      data: [],
    }
  }, 
  1: {
    titulo: 'Até 4 Salários',
    color: 'rgb(0, 209, 224)', 
    lista: {
      header: ["GRUPO", "SETOR", "NOME", "VENDA TOTAL", "VENDA 4G", "ELEGÍVEL"],
      data: [],
    }, 
    sidebar: {
      header: ["GRUPO", "ELEGÍVEIS", "PARTICIPANTES"],
      data: [],
    }
  },
   10: {
    titulo: 'Mega campeões',
    color: 'rgb(232, 92, 227)', 
    lista: {
      header: ["GRUPO", "SETOR", "NOME", "VENDA TOTAL", "VENDA 4G", "POSITIVAÇÃO PERIODO", "POSIÇÃO","ELEGÍVEL"],
      data: [],
    }, 
    sidebar: {
      header: ["GRUPO", "QTD DE PREMIOS", "ELEGÍVEIS"],
      data: [],
    }
  },
}

// 1	4 Salarios
// 2	4 Salarios Quadro Resumo
// 3	4 Salarios Quadro Resumo Elegíveis
// 4	Campeões de Vendas
// 5	Campeões Vendas Quadro Resumo
// 6	Final de Semana Geral
// 10	Mega campeao

const enumColors = [
  '#84CEFF', //3D9EDF
  '#00F24D',
  '#E85CE3',
]

const PRODUTOS = {
  0: {
    titulo: 'TODOS OS GRUPOS',
    color: '#E85CE3', 
    lista: {
      header: ["QUANTIDADE DE ITENS", "CÓDIGO", "MARCA","PRODUTO", "BASE"],
      data: [],
    }, 
  }, 
   1: {
    titulo: 'POSITIVAÇÃO',
    color: '#9B129B', 
    lista: {
       header: ["QUANTIDADE DE ITENS", "CÓDIGO", "PRODUTO", "BASE"],
      data: [],
    }, 
  }, 
   2: {
    titulo: 'GPEP',
    color: '#BA28B5', 
    lista: {
       header: ["QUANTIDADE DE ITENS", "CÓDIGO","MARCA", "PRODUTO", "BASE"],
      data: [],
    }, 
  }, 
    3: {
    titulo: 'GDIF',
    color: '#D93DD3', 
    lista: {
       header: ["QUANTIDADE DE ITENS", "CÓDIGO","MARCA", "PRODUTO", "BASE"],
      data: [],
    }, 
  }, 
    4: {
    titulo: 'GPA',
    color: '#E65DED', 
    lista: {
       header: ["QUANTIDADE DE ITENS", "CÓDIGO","MARCA", "PRODUTO", "BASE"],
      data: [],
    }, 
  }, 
     5: {
    titulo: 'GLAC',
    color: '#F685FC', 
    lista: {
       header: ["QUANTIDADE DE ITENS", "CÓDIGO","MARCA", "PRODUTO", "BASE"],
      data: [],
    }, 
  }, 


   
}

export {
  RESULTADOS,
  enumColors,
  PRODUTOS,
}



