import React, {useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import Profile from '../../pages/Profile'
import {uploadImage} from '../../core/ducks/user.duck'

export default (props) => {
  const {user} = useSelector(state=>state.userReducer)
  const dispatch = useDispatch()
  const [profileImg, setProfileImg] = useState(null)

  const sendImage = () => {
    const payload = {
      imagem: profileImg.file,
      id: user.id
    }

    dispatch(uploadImage(payload, ()=>callBackSuccess(), ()=>callBackError()))
  }

  const callBackSuccess = () => {
    alert("Imagem atualizada!")
    setProfileImg(null)
  }

  const callBackError = () => {
    alert("Ocorreu um erro. Tente novamente.")
  }


  const _handleImageChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setProfileImg({
        file: file,
        imagePreviewUrl: reader.result
      })
    }

    reader.readAsDataURL(file)
  }


  return <Profile user={user} picture={profileImg} _handleImageChange={(e)=>_handleImageChange(e)} saveImg={()=>sendImage()} />
}