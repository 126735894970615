import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getProdutos} from '../../core/ducks/produtos.duck'
import Medicamentos from '../../pages/Medicamentos'




//   default: 0,
//   positivacao: 1,
//   GPEP: 2,
//   GDIF: 3,
//   GPA: 4,
//   GLAC:5



export default (props) => {

  const dispatch = useDispatch()
 
  const { data, loading} = useSelector(state => {
    return state.produtosReducer
  })

  const config = useSelector(state => state.configReducer.data)

   const [type, setType] = useState(0)
  const [filtertext, setFilterText] = useState('')
  
  const changeType = (val) => {
    setType(val)
  }

  useEffect(() => {
    dispatch(getProdutos(type))
  }, [dispatch, type])

  const filtroAction = val => {
    return (
      val.brand.toUpperCase().indexOf(filtertext.toUpperCase()) > -1 ||
       val.name.toUpperCase().indexOf(filtertext.toUpperCase()) > -1 ||
      val.id == filtertext
    ) 
  }

  return (
  
    <Medicamentos
      config={{ titulo: "PRODUTOS VIGENTES", color: '#006796', ...config }}
      type={type}
      data={data}
      loading={loading}
      changeType={(e) => changeType(e)}
      filterText={(e) => setFilterText(e)}
      filtroAction={(v) => filtroAction(v)}
     
     />
  )
}