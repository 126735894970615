import React, {useState} from "react";
import Skeleton from '../Skeleton'
import DivMural from '../../components/divMural'
import DivMuralNew from '../../components/divMuralNew'
import ModalConfirmacao from "../../components/modalConfirmacao"
import moment from 'moment';
import { BeatLoader } from 'react-spinners'
import "./style.css";

function Mural(props) {
  const dataNow = moment().format('DD/MM/Y');
  const [escrever, setEscrever] = useState(false);
  const [enviado, setValueEnviado] = useState(false);
  const [preencher, setValuePreencher] = useState(false);

  const enviaMensagem = (v) => {
    if(v === ''){
      setValuePreencher(true);
      return false;
    }
    props.action(v)
    setValueEnviado(true);
    setEscrever(false);
  }
  return (
    <Skeleton menuHidden>
      
      <div style={{textAlign: 'left', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'flex'}}>
        <div className="botaoRegulamento">MURAL</div>
        <div className="botaoEdit" onClick={() => setEscrever(true)}>ESCREVER NO MURAL</div>
      </div>
      
      <div style={{display:'flex', flexWrap: 'wrap', marginTop: 20, minHeight:400, overflowY: 'auto'}}>
        {props.loading && (
          <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:300, width: '100%'}}>
          <BeatLoader
            sizeUnit={"px"}
            size={15}
            color={'rgba(47, 119, 146)'}
            loading={props.loading}
          />
          </div>
        )}
        {escrever&&(
          <DivMuralNew nome={props.user.nome} user={props.user} data={dataNow} action={(v) => enviaMensagem(v)} />
        )}
        {props.list.map((v) => <DivMural dados={v} />)}
      </div>

      {enviado&&(
        <ModalConfirmacao title="Aguardando aprovação!" botao="Fechar" closeModal={()=>setValueEnviado(false)} />
      )}
      
      {preencher&&(
        <ModalConfirmacao title="Favor preencher todos os campos." botao="OK" closeModal={()=>setValuePreencher(false)} />
      )}

    </Skeleton>
  );
}

export default Mural;
