import React, {useState, useEffect, useCallback} from 'react'

import {useHistory} from 'react-router-dom'
import {useSelector, useDispatch, shallowEqual} from 'react-redux'
import {getObjetivos} from '../../core/ducks/objetivos.duck'

import ObjetivosAno from '../../pages/ObjetivosAno'

export default (props) => {
  const {loading, obj_per_data} = useSelector(state=>state.objetivosReducer)
  const config = useSelector(state=>state.configReducer.data)
  const dispatch = useDispatch()
  const [type] = useState('periodo')
  
  useEffect(()=>{
    dispatch(getObjetivos(type))
  }, [type])

  return <ObjetivosAno data={obj_per_data} loading={loading} type={"PERIODO"} config={config} />
}