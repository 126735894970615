import React from "react";

export default props => {
  return (
    <>
      <span style={{ color: "white", marginBottom: 20 }}>Grupos</span>
      <div style={{ flex: 1 }}>
          <span
            className={"btPremios"}
            style={{ backgroundColor: "#9B129B" }}
            onClick={() => props.action(1)}>
            Positivacão
          </span>
      
          <span
            className={"btPremios"}
            style={{ backgroundColor: "#BA28B5" }}
            onClick={() => props.action(2)}
          >
           GPEP
          </span>
        <span
          className={"btPremios"}
          style={{ backgroundColor: "#D93DD3" }}
          onClick={() => props.action(3)}
        >
        GDIF
        </span>
          <span
            className={"btPremios"}
            style={{ backgroundColor: "#E65DED" }}
            onClick={() => props.action(4)}
          >
          GPA
          </span>    
      </div>
        <span
          className={"btPremios"}
          style={{  backgroundColor: '#F685FC' }}
          onClick={() => props.action(5)}
        >
          GLAC
        </span>
    </>
  );
};
