import * as ResultadosAPI from "../services/resultados.service"
import {RES_ENUM} from '../utils'

const initial = {
  data: RES_ENUM[4],
  loading: false, 
  trimestres: [],
}

export default (state=initial, action)=>{
  switch(action.type){
    case 'RESULTADOS_SET_LOADING':
      return {
        ...state, 
        loading: action.payload,
      }
    case 'RESULTADOS_SET_DATA':
      return {
        ...state, 
        data: action.payload, 
        loading: false,
      }
    case 'RESULTADOS_SET_TRIMESTRES':
      return {
        ...state, 
        trimestres: action.payload
      }
    default:
      return state
  }
}

export const getResultados = (type, type2) => async dispatch => {

  dispatch({type: 'RESULTADOS_SET_LOADING', payload: true})
  let retorno = []
  let retorno_side = []

  retorno = await ResultadosAPI.getResultados(type)
  if (type2) {
    retorno_side = await ResultadosAPI.getResultadosLaterals(type2)
  }
  

  let payload = RES_ENUM[type]
  payload.lista.data = retorno.data instanceof Array ? retorno.data : []
  payload.sidebar.data = retorno_side.data instanceof Array ? retorno_side.data : []

  dispatch({type:"RESULTADOS_SET_DATA", payload: payload})
}

export const listaTrimestres = () => async dispatch => {
  const retorno = await ResultadosAPI.listaTrimestres()

  dispatch({type:"RESULTADOS_SET_TRIMESTRES", payload: retorno.data})
}

export const getTrimestre = (id, type) => async dispatch => {
  dispatch({type: 'RESULTADOS_SET_LOADING', payload: true})
  const retorno = await ResultadosAPI.getTrimestre(id)

  let payload = RES_ENUM[type]
  payload.lista.data = retorno.data instanceof Array ? retorno.data : []
  payload.sidebar.data = []

  dispatch({type:"RESULTADOS_SET_DATA", payload: payload})
}

// 1	4 Salarios
// 2	4 Salarios Quadro Resumo
// 3	4 Salarios Quadro Resumo Elegíveis
// 4	Campeões de Vendas
// 5	Campeões Vendas Quadro Resumo
// 6	Final de Semana Geral