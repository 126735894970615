import {
  createStore, 
  combineReducers, 
  applyMiddleware, 
} from 'redux'

import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' 

import userReducer from './user.duck'
import objetivosReducer from './objetivos.duck'
import muralReducer from './mural.duck'
import resultadosReducer from './resultados.duck'
import ganhadoresReducer from './ganhadores.duck'
import configReducer from './config.duck'
import produtosReducer from './produtos.duck'


const persistConfig = {
  key: 'root',
  storage,
}


const combined = combineReducers({
  userReducer,
  objetivosReducer,
  muralReducer,
  resultadosReducer,
  ganhadoresReducer,
  configReducer,
  produtosReducer
})

const persistedReducer = persistReducer(persistConfig, combined)

const store = createStore(persistedReducer, applyMiddleware(thunk))
const persistor = persistStore(store)

export {store, persistor}