import React from "react"

import {Provider} from 'react-redux'
import {store, persistor} from '../../core/ducks'
import { PersistGate } from 'redux-persist/integration/react'
import {BrowserRouter as Router} from "react-router-dom"

import Content from './content'

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Content />
        </Router>
      </PersistGate>
    </Provider>
  )
}
