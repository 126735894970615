import React from "react";
import Skeleton from "../Skeleton";
import LinhaTabela from "../../components/linhaTabela";
import LinhaTabelaTres from "../../components/linhaTabelaTres";
import ChangeType from '../../components/changeType'
import Apuracao from '../../components/apuracao'

import { BeatLoader } from 'react-spinners'

const headWidth = {
  GRUPO: { width: 180 },
  'GRUPO MEGA': { width: 140 },
  SETOR: { width: 80 },
  'VENDA 4G': { width: 80},
  'VENDA TOTAL': { width: 80},
  ELEGÍVEL: { width: 60 },
  'POSITIVAÇÃO PERIODO':{ width: 80},
  'POSIÇÃO':{ width: 80 },
  DEFAULT: { flex: 1 }
}

const headSideWidth = {
 group: { 
     
      flex: 2 
    },
    quantity:{ 
     
      flex: 1 
    },
    participants:{
     
      flex: 1, 
    },
    premio: {
       
      flex: 1 
    },
    qtdparticipantes: {
       
      flex: 1
    },
    DEFAULT: { flex: 1 }
}

export default props => {
  const data = props.data
  const config = props.config
  const trimestres = props.trimestres || []
  const actionTrimestre = props.actionTrimestre || (() => { })

  return (
    <Skeleton menuHidden>
      <Apuracao config={config} />
      <div style={{ display: "flex" }}>
        <div className="w100"></div>
        
        <div className="flex2 flexTopo">
          <div className="tituloCaps" style={{ backgroundColor: props.config.color, float: "left" }}>
            {props.config.titulo}
          </div>

          <span className="tituloSecundario" style={{ color: data.color }}>{data.titulo}</span>
          <span className="subtitulo1">APURAÇÃO PARCIAL</span>

          {/* {
            props.type === 6 && trimestres.length > 0 && (
              <div className="baseFilter" style={{ marginTop: 10, marginLeft: 10 }}>
                <select onChange={(e) => actionTrimestre(e.target.value)}>
                  <option>Selecionar Trimestre</option>
                  {trimestres.map(v => {
                    return <option value={v.id}>{v.titulo}</option>
                  })}
                </select>
              </div>
            )
          } */}
        </div>

        <div className="flex1">
          <div className="baseFilter">
            <input type="text" placeholder="Pesquise aqui por NOME, SETOR ou GRUPO" onChange={(e) => props.filterText(e.target.value)} />
          </div>
        </div>

      </div>

      {/* Responsavel por mandar o  setar os dados das linhas da tabela*/}
      <div style={{ display: "flex", marginTop: 20 }}>
        <div className="w100" style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
          <ChangeType 
            screen={props.screen} 
            type={props.type} 
            action={(e, e2) => props.changeType(e, e2)} 
            changeElegivel={() => props.changeElegivel()} 
          />
        </div>
        <div className="flex2">

          <div className="baseTable">
            {/* responsavel por pegar o header das listas na tabela e criar os titulos da tabela */}
            <div className="headerTable">
              {data.lista.header.map((v, i) => <strong style={{...(headWidth[v.toUpperCase()] ? (props.type == 10 && v == 'GRUPO' )?headWidth['GRUPO MEGA'] :headWidth[v.toUpperCase()] : headWidth.DEFAULT) }} key={i}>{v}</strong>)}
            </div>


            <div className="baseTableConteudo">
              {props.loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 300 }}>
                  <BeatLoader
                    sizeUnit={"px"}
                    size={15}
                    color={'rgba(47, 119, 146)'}
                    loading={props.loading}
                  />
                </div>
              )}

              {/* manda os valores das prolps para o tracker das linhas da tabela */}
              {!props.loading && data.lista.data.filter(props.filtroAction).filter(props.elegiveisAction).map((v, i) => 
                <LinhaTabela  imagem={props.imagem} type={props.type} periodo={v.periodo} posicao={v.posicao} grupo={v.grupo} setor={v.setor} nome={v.nome} gep={v.gdvi} gdvi={v.gdvi}  gr={v.gr} quantidade={v.quantidade} elegivel={v.tipo == 'ELEGÍVEL' ? true : false} />
              )
              }
              {/* usado quando não tem dados da tabela */}
              {!props.loading && data.lista.data.filter(props.filtroAction).filter(props.elegiveisAction).length === 0 && <div className="tableMensagem">AINDA NÃO HÁ GANHADORES PARA ESTE PRÊMIO</div>}
            </div>
          </div>

        </div>

        {/* mesma logica da tabela de cima so que essa alimenta as tabelas pequenas */}
        {props.type !== 6   && (
          <div className="flex1">
            <div className="baseTable" style={{ marginLeft: 20 }}>
              <div className="headerTable">
                {data.sidebar.header.map((v, i) => <strong style={{ ...(headSideWidth[v.toUpperCase()] ? headSideWidth[v.toUpperCase()] : headSideWidth.DEFAULT) }} key={i}>{v}</strong>)}
              </div>
              <div className="baseTableConteudo">
                {!props.loading && data.sidebar.data.map((v, i) => <LinhaTabelaTres type={props.type} grupo={v.nome != null ? v.nome : v.grupo} qtd={v.gdvi} participantes={v.gr} premio={v.premio} qtdparticipantes={v.participantes} />)}
                {!props.loading && data.sidebar.data.length === 0 && <div className="tableMensagem">AINDA NÃO HÁ GANHADORES PARA ESTE PRÊMIO</div>}
              </div>
            </div>
          </div>
        )}
      </div>
    </Skeleton>
  );
};
