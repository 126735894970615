import axios from './axios'

export async function login(payload){
  return await axios.post('colaborador?acao=login', payload)
}

export async function aceitaTermos(payload){
  

  return await axios.post('colaborador?acao=aceitaTermos', payload)
}

export async function updateImage(payload){

    let dados = new FormData()
    Object.keys(payload).map(e=>{
      dados.append(e, payload[e])
    })

  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }

  return await axios.post('configuracao?acao=atualizaImagem', dados, config)

}

