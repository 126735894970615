import * as ProdutosAPI from "../services/produtos.service"
import {PROD_ENUM} from '../utils'

const initial = {
   data: PROD_ENUM[0],
  loading: false,
}

export default (state=initial, action)=>{
   switch(action.type){
    case 'PRODUTOS_SET_LOADING':
      return {
        ...state, 
        loading: action.payload,
      }
    case 'PRODUTOS_SET_DATA':
      return {
        ...state, 
        data: action.payload, 
        loading: false,
      }
    default:
      return state
  }
}

export const getProdutos = (type) => async dispatch => {
const produtosTypes = {
   0: undefined,
  1: 'positivacao',
  2: 'GPEP',
  3: 'GDIF',
  4: 'GPA',
  5: 'GLAC'
} 
 
  dispatch({ type: 'PRODUTOS_SET_LOADING', payload: true })
  
  let retorno = []
  
  retorno = await ProdutosAPI.getProdutos(produtosTypes[type])

  let payload = PROD_ENUM[type]
  payload.lista.data = retorno.data instanceof Array ? retorno.data : []
  
  dispatch({type:"PRODUTOS_SET_DATA", payload: payload})
}


