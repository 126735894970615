import axios from './axios'
import {getDateRequest} from '../utils/dateRequest.js'


export async function getlist(payload) {
 return await axios.get(`mural?acao=lista${getDateRequest()}`)
}

export async function create(payload){

  return await axios.post('mural?acao=escreveMural', payload)
}


export async function contato(payload){
console.log('console',payload)

  return await axios.post('mural?acao=escreverContato', payload)
}