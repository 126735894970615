import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getResultados, listaTrimestres, getTrimestre } from '../../core/ducks/resultados.duck'
import Ganhadores from '../../pages/Ganhadores'
import { useHistory } from 'react-router-dom'

const datadefault = {
  default: [4, 5],
  campeao: [4, 5],
  final: [6, null],
  salario: [1, 2],
  mega:[10,11],
}
export default (props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  let typeparam = 'default'
  if (history.location.state) {
    typeparam = history.location.state.type
  }

  const { data, loading, trimestres } = useSelector(state => {
    return state.resultadosReducer
  })
  const config = useSelector(state => state.configReducer.data)
  const [type, setType] = useState(datadefault[typeparam][0])
  const [type2, setType2] = useState(datadefault[typeparam][1])
  const [filtertext, setFilterText] = useState('')
  const [elegiveis, setElegiveis] = useState(false)

  /*
  campeao 4,5
  final 6
  salario 1,2
  */
  

  const changeType = (val, val2) => {
    setType(val)
    setType2(val2)
  }

  const filtroAction = val => {
    return (
      val.nome.toUpperCase().indexOf(filtertext.toUpperCase()) > -1 ||
      val.setor.toUpperCase().indexOf(filtertext.toUpperCase()) > -1 ||
      val.grupo.toUpperCase().indexOf(filtertext.toUpperCase()) > -1
    )
  }

  const changeElegivel = () => {
    setElegiveis(!elegiveis)
  }

  const elegiveisAction = (val) => {
    if (elegiveis) {
      return val.tipo === "ELEGÍVEL"
    } else {
      return true
    }
  }

  const actionTrimestre = val => {
    // filtra trimestre
    dispatch(getTrimestre(val, type))
  }

  useEffect(() => {
    console.log('type', type)
    console.log('type2',type2)
    dispatch(getResultados(type, type2))
    if (type === 6) { // get trismestres final de semana 
      dispatch(listaTrimestres())
    }
  }, [dispatch, type, type2])

  return (
    <Ganhadores
      config={{ titulo: "RESULTADOS", color: '#006796', ...config }}
      type={type}
      data={data}
      loading={loading}
      changeType={(e, e2) => changeType(e, e2)}
      filterText={(e) => setFilterText(e)}
      filtroAction={(v) => filtroAction(v)}
      elegiveisAction={(v) => elegiveisAction(v)}
      changeElegivel={() => changeElegivel()}
      trimestres={trimestres}
      actionTrimestre={(e) => actionTrimestre(e)}
    />
  )
}