import React from "react";

export default props => {
  return (
    <>
      <span style={{ color: "white", marginBottom: 20 }}>Prêmios</span>
      <div style={{ flex: 1 }}>
      {props.type != 7 && ( 
          <span
            className={"btPremios"}
            style={{ backgroundColor: "#D93DD3" }}
            onClick={() => props.action(10, 11)}
          >
            Mega<br />
            Campeões
          </span>
        )} 
         {props.type != 7 && ( 
          <span
            className={"btPremios"}
            style={{ backgroundColor: "orange" }}
            onClick={() => props.action(4, 5)}
          >
            Campeões
            <br />
            de Vendas
          </span>
        )} 

        <span
          className={"btPremios"}
          style={{ backgroundColor: "rgb(25, 218, 0)" }}
          onClick={() => props.action(props.screen=='ganhadores'?7:6, null)}
        >
          Casa
          <br />
          Equipada
        </span>

        {props.type != 7 && (
          <span
            className={"btPremios"}
            style={{ backgroundColor: "rgb(0, 209, 224)" }}
            onClick={() => props.action(1, 2)}
          >
            Até 4<br />
            Salários
          </span>
       )} 
         
      </div>

      {props.type != 7 && (
        <span
          className={"btPremios"}
          style={{ borderWidth: 1, borderColor: '#FFFFFF' }}
          onClick={() => props.changeElegivel()}
        >
          Apenas
          <br />
          Elegíveis
        </span>
      )}
    </>
  );
};
