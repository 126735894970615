import React from "react";

export default props => {
  return (
    <div style={{position: 'fixed', left: 0, top: 0, width: '100%', height: '100vh', backgroundColor: 'rgba(0, 0, 0, .6)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <div className="baseModal">
          <div style={{fontSize: 30, textAlign: 'center'}}>{props.title}</div>
      
          <div onClick={() => props.closeModal()} className="botao">
          {props.botao}
          </div>
        </div>
    </div>    
  );
};
