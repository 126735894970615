import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {getData} from '../../core/ducks/ganhadores.duck'
import Ganhadores from '../../pages/Ganhadores'

export default (props) => {
  const {data, loading} = useSelector(state=>state.ganhadoresReducer)
  const config = useSelector(state=>state.configReducer.data)
  let [type, setType] = useState(7)
  const dispatch = useDispatch();
  const [filtertext, setFilterText] = useState('')
  const [elegiveis, setElegiveis] = useState(false)

  useEffect(()=>{
    dispatch(getData())
  }, [type])

  const changeType = val => {
    setType(val)
  }

  const filtroAction = val => {
    return (
      val.nome.toUpperCase().indexOf(filtertext.toUpperCase())>-1 ||
      val.setor.toUpperCase().indexOf(filtertext.toUpperCase())>-1 || 
      val.grupo.toUpperCase().indexOf(filtertext.toUpperCase())>-1
    )
  }

  const changeElegivel = () => {
    setElegiveis(!elegiveis)
  }
  
  const elegiveisAction = (val) => {
    if(elegiveis){
      return val.tipo==="ELEGÍVEL"
    }else{
      return true
    }
  }

  return <Ganhadores 
          config={{titulo: "ELEGÍVEIS", color: 'orange', ...config}}
          type={type}
          data={data}
          loading={loading}
          screen={'ganhadores'}
          changeType={(e)=>changeType(e)}
          filterText={(e)=>setFilterText(e)}
          filtroAction={(v)=>filtroAction(v)}
          elegiveisAction={(v)=>elegiveisAction(v)}
          changeElegivel={()=>changeElegivel()}
          />
}