import * as UserAPI from '../services/user.service'

export const type = {
  USER_LOGIN_SET: 'USER_LOGIN_SET',
  USER_LOGIN_LOADING: 'USER_LOGIN_LOADING',
  USER_LOGOUT: 'USER_LOGOUT',
  USER_SET_AVATAR: 'USER_SET_AVATAR',
}

const initial = {
  user: {},
  data: {},
  loading: false,
}

const reducer = (state = initial, action) => {
  switch(action.type){
    case type.USER_LOGIN_SET:
      return {
        ...state, 
        ...action.payload,
      }
    case type.USER_LOGIN_LOADING:
      return {
        ...state, 
        loading: action.payload
      }

    case type.USER_LOGOUT:
      return {
        ...initial,
      }

    case type.USER_SET_AVATAR:
      return {
        ...state, 
        user: {
          ...state.user, 
          imagem: action.payload,
        },
      }
    
    default: 
      return state
  }
}

export default reducer 

export const login = (data, callback) => async dispatch => {
  dispatch({type: type.USER_LOGIN_LOADING, payload: true})

  const user = await UserAPI.login(data)
  
  if(!user.data.id){
    //erro 
    callback()
    dispatch({type: type.USER_LOGIN_LOADING, payload: false})
    return
  }

  const payload = {
    user: {
      ...user.data,
    }
  }

  dispatch({type: type.USER_LOGIN_SET, payload: payload})
}

export const aceitaTermos = data => async dispatch => {
  dispatch({type: type.USER_LOGIN_LOADING, payload: true})

  const aceite = await UserAPI.aceitaTermos(data)
}

export const uploadImage = (payload, success, error) => async dispatch => {
  const img = await UserAPI.updateImage(payload)

  if(img.data!==""){
    dispatch({type:type.USER_SET_AVATAR, payload: img.data})
    success()
  }else{
    error()
  }
}

export const logoutAction = () => ({type:type.USER_LOGOUT})