import * as ObjetivosAPI from '../services/objetivos.service'

export const type = {
  OBJ_LOADING: 'OBJ_LOADING',
  OBJ_ANO_SET: 'OBJ_ANO_SET',
  OBJ_PER_SET: 'OBJ_PER_SET',
}

const initial = {
  loading: false,
  obj_ano_data: [
    {
      titulo: 0,
      valor: 0,
      valor_rodape: 0,
      porcentagem: 0,
    },
    {
      titulo: 0,
      valor: 0,
      valor_rodape: 0,
      porcentagem: 0,
    },
    {
      titulo: 0,
      valor: 0,
      valor_rodape: 0,
      porcentagem: 0,
    }
  ],
  obj_per_data: [
    {
      titulo: 0,
      valor: 0,
      valor_rodape: 0,
      porcentagem: 0,
    },
    {
      titulo: 0,
      valor: 0,
      valor_rodape: 0,
      porcentagem: 0,
    },
    {
      titulo: 0,
      valor: 0,
      valor_rodape: 0,
      porcentagem: 0,
    }
  ],
}

const reducer = (state=initial,action)=>{
  switch(action.type){
    case type.OBJ_LOADING:
      return {
        ...state, 
        loading: action.payload,
      }

    case type.OBJ_ANO_SET:
      return {
        ...state, 
        loading: false,
        obj_ano_data: action.payload
      }
    
    case type.OBJ_PER_SET:
      return {
        ...state, 
        loading: false,
        obj_per_data: action.payload
      }

    default:
      return state
  }
}

export default reducer 

export const getObjetivos = (filter) => async dispatch => {
  dispatch({type: type.OBJ_LOADING, payload: true})

  const retorno = await ObjetivosAPI.getObjetivos(filter)
  if(!retorno.data){
    return 
  }

  const dispara = {
    ano: {type: type.OBJ_ANO_SET, payload: retorno.data},
    periodo: {type: type.OBJ_PER_SET, payload: retorno.data}
  }
  dispatch(dispara[filter])
}