import React from "react";
import "./style.css";

export default props => {
  const styles = {
    group: { 
      fontSize: 12, 
      flex: 2 
    },
    quantity:{ 
      fontSize: 12, 
      flex: 1 
    },
    participants:{
      fontSize: 12, 
      flex: 1, 
    },
    premio: {
       fontSize: 12, 
      flex: 1 
    },
    qtdparticipantes: {
       fontSize: 12, 
      flex: 1
    }
  }

  return (
    <div className={"linhaTabelaMenor"}>
      <div style={styles.group}>{props.grupo}</div>
      <div style={styles.premio}>{props.premio}</div>
      <div style={styles.qtdparticipantes}>{props.qtdparticipantes}</div>
    </div>
  );
};
