import * as GanhadoresAPI from "../services/ganhadores.service"
import * as ResultadosAPI from "../services/resultados.service"
import {RES_ENUM} from '../utils'

const initial = {
  data: RES_ENUM[6],
  loading: false, 
}

export default (state=initial, action)=>{
  switch(action.type){
    case 'GANHADORES_SET_LOADING':
      return {
        ...state, 
        loading: action.payload,
      }
    case 'GANHADORES_SET_DATA':
      return {
        ...state, 
        data: action.payload, 
        loading: false,
      }
    default:
      return state
  }
}

export const setLoading = val => ({type: "GANHADORES_SET_LOADING", payload: val})

export const getData = () => async dispatch => {
  dispatch({type: "GANHADORES_SET_LOADING", payload: true})
  const retorno = await GanhadoresAPI.getData()

  let payload = RES_ENUM[6]
  payload.lista.data = retorno.data instanceof Array ? retorno.data : []
 

  const retorno_side = await ResultadosAPI.getResultadosLaterals(8)
  payload.sidebar.data = retorno_side.data instanceof Array ? retorno_side.data : []

  
  dispatch({type:"GANHADORES_SET_DATA", payload: payload})
}
