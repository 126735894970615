import React from "react";
import Topo from "../../components/topo";
import Body from "../../components/body";
import Menu from '../../components/menu'


export default (props) => {
  return (
    <Body>
      <Topo />
        <div className="baseConteudo">{props.children}</div>
      <Menu hidden={props.menuHidden} />
    </Body>
  );
}

