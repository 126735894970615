import React, {useState, useEffect} from 'react'

import {useHistory} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {login} from '../../core/ducks/user.duck'
import {getConfigs} from '../../core/ducks/config.duck'

import Login from '../../pages/Login'

export default (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {user, loading} = useSelector(state=>state.userReducer)
  const [payload, setPayload] = useState({setor:'', senha:''})

  const actionLogin = () => {
    dispatch(login({setor:payload.setor, senha:payload.senha}, ()=>alert('Dados incorretos.')))
    // dispatch(login({setor:'123123', senha:'12312312312'}, ()=>alert('Dados incorretos.')))
  }

  useEffect(()=> {
    if(user.id){
      
      dispatch(getConfigs())

      if(Number(user.aceite_termos)===1){
        history.push("/home")
      }else{
        history.push("/regulamento")
      }
    }
  }, [dispatch, history, user])

  const changeField = (field, value) => {
    const fields = {
      ...payload,
      [field]: value
    }

    setPayload(fields)
  }

  return <Login actionLogin={()=>actionLogin()} loading={loading} changeLogin={(field, value)=>changeField(field, value)} />
}