import React, {useState, useEffect} from "react"

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";


const enumType = {
  1: "VENDA TOTAL",
  2: "VENDA 4G",
  3: "EQUILIBRIO",
}

function sleep(sec) {
  return new Promise(resolve => setTimeout(resolve, sec))
}

export default (props) => {
  const [startPorc, setStartPorc] = useState(0)
  const [porcentagem, setPorcentagem] = useState(0)
  const [porcentagemTarget, setPorcentagemTarget] = useState(0)
  const [porcBall, setPorcBall] = useState(0)
  const data = props.data
  const valores1 = data.id == 6
  const valores2 = data.id == 3
  // const grafico3 = valores1 || valores2
  const grafico3 = data.id == 3 || data.id == 6
  
  const anima = async () => {
    if(startPorc < parseFloat(data.porcentagem)) {
      await sleep(50)
      setStartPorc(startPorc + 1)
    }
  }

  useEffect(()=>{
    setPorcentagem(startPorc)
    setPorcentagemTarget((porcentagem) - ((porcentagem * 25)/100))
    setPorcBall((porcentagemTarget*360) / 100)
    anima()
  }, [anima, porcentagem, porcentagemTarget, startPorc])

  return (
    <div >
      <div style={{paddingBottom:16,textAlign:'center'}}>
        <div className='divChartTitulo'>{enumType[data.objetivo_tipo]}</div>
        <div className='divChartValor' style={{ color: props.color }}>{`${!grafico3 ? 'R$' : ''} ${data.valor_total}`}</div>
      </div>
    
      
    <div className='divChart'>
      <div className='divChartPorcentagemBase' style={{backgroundColor: props.color}}>
        <div className={!grafico3 ? 'divChartPorcentagem' : 'divChartPorcentagemEquilibrio'}>
        <img src={require('../assets/images/liquid1.png')} className="liquid1" alt={'liquid1'} />
        <img src={require('../assets/images/liquid2.png')} className="liquid2" alt={'liquid2'} />
        {!grafico3 ? porcentagem : data.valor}
        <span className='divChartPorcento'>{`${!grafico3 ? '%' : ''}`}</span>
        </div>
      </div>

      <div className="graficoBase">
          <CircularProgressbar
            value={(100 - 0) - 25} 
            strokeWidth={2}
            styles={buildStyles({
              rotation: .625,
              strokeLinecap: "round",
              pathTransitionDuration: 2,
              pathColor: `#EDC620`,
              trailColor: "rgba(0,0,0,.0)"
            })}
          />
        </div>

      <div className="graficoBase">
          <CircularProgressbar
            value={porcentagemTarget} 
            strokeWidth={2}
            styles={buildStyles({
              rotation: .625,
              strokeLinecap: "round",
              pathTransitionDuration: 2,
              pathColor: props.color,
              trailColor: "rgba(0,0,0,.0)"
            })}
          />
        </div>

        <div className="graficoContBall">
          <div className="graficoContPin" style={{transform: `rotate(${porcBall}deg)`}} >  
          <div style={{width:20, height:20, borderRadius:10, backgroundColor:props.color, boxShadow:`0px 0px 10px ${props.color}`}} />
          </div>
        </div>

      </div>
      <div className='divCharValorRodape' style={{color: props.color}}>
        {!grafico3 && `R$ ${data.valor}`}
      </div>
    </div>
  );
};
