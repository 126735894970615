import React from "react";

export default props => {


  const styles = {
    quantity: {
      width: 120,
      fontSize: 12,
    },
    id: {
      width: 80,
      fontSize: 12,
    },
    brand: {
      flex: 1,
      fontSize: 12
    },
    type: { width: 80, fontSize: 12 },
     marca: {
      width: 140,
      fontSize: 12,
    },
  }
  
  return (
    <div className="linhaTabela">
      <div style={styles.quantity}>{props.quantity}</div>
      <div style={styles.id}>{props.id}</div>
      {props.type !== 1 &&<div style={styles.marca}>{props.name}</div>}  
      <div style={styles.brand}>{props.brand}</div>
      <div style={styles.type}>{props.base}</div>

    
    </div>
  );
};

