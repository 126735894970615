import React from "react";
import Skeleton from '../Skeleton';
import DivChart from '../../components/divChart';
import Apuracao from '../../components/apuracao'
import { BeatLoader } from 'react-spinners'

const enumColors = [
  '#84CEFF', //3D9EDF
  '#00F24D',
  '#E85CE3',
]

function ObjetivosAno(props) {
  const {data, config} = props
  
  return (
    <Skeleton>
      <Apuracao config={config} />
      <div className="containerObjetivos">
        <div className="tituloCaps" style={{ float: "left" }}>OBJETIVOS <span style={{color:'#EDC620'}}>{props.type}</span> </div>
  
        {props.loading && (
          <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:300}}>
          <BeatLoader
            sizeUnit={"px"}
            size={15}
            color={'#fff'}
            loading={props.loading}
          />
          </div>
        )}

        {!props.loading && (
          <div className="baseCharts">
          {
            data.map((val, i)=> <DivChart data={val} color={enumColors[i]} key={i} />)
          }
          </div>
        )}
      </div>

    </Skeleton>
  );
}

export default ObjetivosAno;
