import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch,} from 'react-redux'
import {getObjetivos} from '../../core/ducks/objetivos.duck'

import ObjetivosAno from '../../pages/ObjetivosAno'

export default (props) => {
  const {loading, obj_ano_data} = useSelector(state=>state.objetivosReducer)
  const config = useSelector(state=>state.configReducer.data)
  const dispatch = useDispatch()
  const [type] = useState('ano')
  
  useEffect(()=>{
    dispatch(getObjetivos(type))
  }, [dispatch, type])

  return <ObjetivosAno data={obj_ano_data} loading={loading} type={"ANO"} config={config} />
}