import * as ConfigAPI from "../services/config.service"

export const type = {
  CONFIG_SET_LOADING: 'CONFIG_SET_LOADING',
  CONFIG_SET_DATA: 'CONFIG_SET_DATA'
}

const initial = {
  data: {},
  loading: false, 
}

const reducer = (state=initial, action)=>{
  switch(action.type){
    case type.CONFIG_SET_LOADING:
      return {
        ...state, 
        loading: action.payload,
      }
    case type.CONFIG_SET_DATA:
      return {
        ...state, 
        data: action.payload
      }
    default:
      return state
  }
}

export default reducer 

export const getConfigs = data => async dispatch => {
  dispatch({type: type.CONFIG_SET_LOADING, payload: true})

  const config = await ConfigAPI.getConfig()
  dispatch({type: type.CONFIG_SET_DATA, payload: config.data[0]})
}