import React from 'react'

export default ({config})=>{
  return (
    <div style={{ display: "flex" }}>
      <div className="flex1 flexTopo primeiraLinha">
        <span style={{marginRight:60}}>Apuração até <span style={{color: '#EDC620', textTransform: 'uppercase'}}>{config.apuracao_ate}</span></span> 
      </div>
    </div>
  )
}