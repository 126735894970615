import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logoutAction } from '../core/ducks/user.duck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import selo from "../assets/images/selo.png";
import logoHorizontal from "../assets/images/logo_horizontal.png";



const LogoBase = () => {
  const history = useHistory()
  return (
      <div  className="logoBase" onClick={()=>history.push("/home")}>
      <img src={logoHorizontal} style={styles.logoHorizontal} alt={'logo horizontal'} />
       <img src={selo} style={styles.selo} alt={'Selo 50 anos'} />
      </div>
    )
}

export default props => {
  const history = useHistory()
  const {user} = useSelector(state=>state.userReducer)
  const dispatch = useDispatch()

  const signOut = () => {
    dispatch(logoutAction())
    history.push("/login")
  }

  let avatar = require("../assets/images/avatar.png")
  if(user.imagem !== null){
    avatar = process.env.REACT_APP_BASEIMG + user.imagem
  }

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        minHeight:100
      }}
    >
      <div>
        <LogoBase />  
      </div>

      
      <div>
        <div style={{ textAlign: 'right', marginBottom: 16, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <div>

          </div>
          <img src={require('../assets/images/base/cristalia.png')} style={{ width: 120, marginRight: 30 }} alt={'logo'}/>
          <FontAwesomeIcon icon={faSignOutAlt} style={{marginRight:15, color:'white', cursor:'pointer'}} onClick={()=>signOut()} />
        </div>
        
        {!props.notShowStat && (history.location.pathname !== "/resultados") &&(
          
          <div className="basePill">
              <div className="pill" style={{backgroundColor:'rgb(217, 61, 211)'}} onClick={()=>history.push("/resultados", {type:"mega"})}>
              <span>MEGA CAMPEÕES</span>
              <span>{user.final_semana}</span>
            </div>

            <div className="pill" onClick={()=>history.push("/resultados", {type:"campeao"})}>
              <span>CAMPEÕES DE VENDAS</span>
              <span>{user.campeao}</span>
            </div>

            <div className="pill" style={{backgroundColor:'rgb(25, 218, 0)'}} onClick={()=>history.push("/resultados", {type:"final"})}>
              <span>CASA EQUIPADA</span>
              <span>{user.final_semana}</span>
            </div>

            <div className="pill" style={{backgroundColor:'rgb(0, 209, 224)'}} onClick={()=>history.push("/resultados", {type:"salario"})}>
              <span>ATÉ 4 SALÁRIOS</span>
              <span>{user.salarios}</span>
            </div>

            <img
              onClick={()=>history.push("/profile")}
              src={avatar}
              className={"avatarTopo"}
              alt={'avatarTopo'}
            />
          </div>
        )}

      </div>
    </div>
  );
};

const styles = {
  selo: {
    width: 60,
    marginLeft: 16,
  },
  logoHorizontal:{width: 248, height: 79,},

}