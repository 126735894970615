import React from "react";
import Botao from "../../components/botao";
import logo from "../../assets/images/logo.png";
import logoCristalia from "../../assets/images/base/cristalia.png";
import selo from "../../assets/images/selo.png";


function Login(props) {
  return (
    <div className="fundoLogin">
      <div style={styles.container}>
        <img src={logo} style={styles.logo} alt={'Logo Campanha'}/>
        <div style={styles.containerInput}>
          <input
            type="text"
            placeholder="Setor"
            className="input"
            onChange={e => props.changeLogin("setor", e.target.value)}
            style={{ width: 300 }}
          />
          <input
            type="password"
            placeholder="Senha"
            className="input"
            onChange={e => props.changeLogin("senha", e.target.value)}
            style={{ width: 300 }}
          />
            <Botao  texto={props.loading ? "Aguarde..." : "Login"} onClick={() => props.actionLogin()} />
         
          
        </div>
       
          <img src={logoCristalia} style={styles.logoCristalia} alt={'Logo Cristalia'} />
        
        <div style={styles.footer}>
          <img src={selo} style={styles.selo} alt={'Selo 50 anos'} />
        </div>
      
        
      </div>
    </div>
  );
}

const styles = {
  container: {
    flex:1,
    justifyContent: "center",
    textAlign: "center"
  },
  logo: {
    width: 527,
    height: 279,
  },
  containerInput: {
    marginTop: 60, 
    marginBottom: 40, 
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center', 
  },
  footer: {
    display: 'flex', 
    justifyContent: 'center' 
  },
  logoCristalia: {
    width: 140, 
    marginTop: 40 
  },
  selo: {
    width: 80
  }
}

export default Login;
